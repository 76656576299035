<template>
  <v-alert
    prominent
    class="ma-0 pa-5"
  >
    <v-row align="center">
      <v-col class="grow">
        {{this.text}}
      </v-col>
      <v-col class="shrink">
        <v-btn v-if="toTop" link to="/" color="grey"  @click="close" >トップへ</v-btn>
        <v-btn  v-else color="primary"  @click="close" >閉じる</v-btn>
      </v-col>
    </v-row>
  </v-alert>  
</template> 

<script>
export default {
  name: 'DialogComplete',
  props:["text","toTop"],
  data () {
    return {
    }
  },

  methods: {   
    close (){
      this.$emit('closeEmit')
    }
  },
}
</script>
